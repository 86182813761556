.Invoice1{
    height:auto;
    /* display: flex; */
    align-items: start;
    justify-content: center;
    background: white !important;

}
.Invoices1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    padding: 20px !important;
    font-family: 'Just Sans',sans-serif;
    overflow: hidden;
}
#Spcae{
    display: none;
}
.Invoices1 .Invoice-details {
    padding: 10px;
    width: 615px !important;
    min-height:850px;
    background-color: white;
    position: relative;
    border: 1.5px solid black;
    border-radius: 10px;
    /* margin-left: 20%; */
}
 .back {
    font-size: 30px;
    position: absolute;
    left: 18%;
    top: 3%;
    cursor: pointer;
}
.Invoices1 .Invoice-details .Heading h2 {
    text-align: center;
    font-weight: lighter;
}

/* bussiness-info start */
.Invoices1 .Invoice-details .bussiness-info {
    display: flex;
    align-items: center;
    

}

.Invoices1 .Invoice-details .bussiness-info .bussiness-info-details{
    margin-left:30px;
    align-items: center !important;
}
.Invoices1 .Invoice-details .bussiness-info .bussiness-info-details h3 {
    font-size: 24px;
}

.Invoices1 .Invoice-details .bussiness-info .bussiness-info-details p {
    color:dimgray;
    margin:0px 0px;
    font-size: 14px;
    width: 100%;
}

.Invoices1 .Invoice-details .bussiness-info .bussiness-info-logo {
    width: 25%;
    height: 10%;
    /* margin-right: 20px; */
    /* text-align: start !important; */
    margin-left: 20px;
    position: relative;
   

    /* height: 0; */
    margin-bottom: 25px;
}

.Invoices1 .Invoice-details .bussiness-info .bussiness-info-logo img {
    width: 80px;
    height: 80px;
   object-fit: contain;
}

.Invoices1 .logo {
    position: absolute;
    bottom: 0%;
    right: 0%;
    opacity: 1;
}

/* bussiness-info end */

/* Divider Start */
.Invoices1 .Invoice-details .divider_row hr {
    height: 1px;
    border: none;
    background-color: rgb(17, 92, 242);
    /* margin-bottom: 10px; */
}

/* Divider End */


/* customer-info start */
.Invoices1 .Invoice-details .customer-info {
    font-size: 12px;
    margin-bottom:20px;
    /* color: rgb(126, 126, 126); */
}

.Invoices1 .Invoice-details .customer-info .Cusname-invoice-number {
    display: flex;
    align-items: start;
    justify-content: space-between;
    text-align: left;

    font-size: 16px;
    color: black;

}

.Invoices1 .Invoice-details .customer-info .Cusname-invoice-number .Cus-name {
  /* font-size: 16px; */
  /* font-weight:550; */
}
.Invoices1 .Invoice-details .customer-info .Cusname-invoice-number .Cus-name span{
     font-size: 19px;
  font-weight:550;
 color: black;
}
 .Invoices1 .Invoice-details .customer-info .Cusname-invoice-number .Cus-name p{
    margin: 0px !important;
    padding: 0px !important;
 }
.Invoices1 .Invoice-details .customer-info .Cusname-invoice-number .invoice-number div:nth-child(2) {
/* margin:6px 0px; */
}

.Invoices1 .Invoice-details .customer-info .Gst-date {
    display: flex;
    align-items: center;
    /* margin-bottom: 5px; */

}
.Invoices1 .Invoice-details .customer-info .Gst-date .Gst {
    width: 60%;
        visibility: hidden;
}       



.Invoices1 .Invoice-details .customer-info .Adress {

    /* margin-bottom:3px; */
    font-size: 15px;
    color: dimgray;
    font-weight: 550;
}

.Invoices1 .Invoice-details .customer-info .number {
    /* margin-bottom: 3px; */
    font-size: 15px;
    color: dimgray;
    font-weight: 550;
}

.Invoices1 .Invoice-details .customer-info .Email {
    margin-bottom: 10px;
    font-size: 15px;
    color: dimgray;
    font-weight: 550;
}

.Invoices1 .Invoice-details .purshcing-info ol {
    list-style: none;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    /* margin-left: 5%; */
}


.Invoices1 .Invoice-details .purshcing-info .purshcing-info-heading {
    text-align: left;
    width: 100%;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    background-color: rgba(128, 128, 128, 0.516);
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-heading .no-item {
    width: 50%;
    display: flex;
    align-items: center;

}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .no {
    width: 30%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .item {
    width: 60%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-heading .price-amount {
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section1 Start*/
.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section1 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}
.Invoices1 .Invoice-details .purshcing-info .items p{
    font-size:10px;
    margin-bottom:5px;
}
.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section1 ol {
    margin: 0px;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;

}

.Invoices1 .Invoice-details .purshcing-info .items{
    margin: 0;
    margin-left:1%;
    font-size: 12px;
    color: gray;
}


.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .item {
   display: block;
    width: 70%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section1 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section2 Start*/
.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section2 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: white;

}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item {
    width: 40%;
    display: flex;
    align-items: center;

}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .no {
    width: 20%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .item {
    width: 70%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section2 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10% !important;
}

/*  purshcing-info-section2 End*/

/*  purshcing-info-section3 Start*/
.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section3 {
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item {
    width: 30%;
    display: flex;
    align-items: center;

}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .no {
    width: 20%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .item {
    width: 70%;
}

.Invoices1 .Invoice-details .purshcing-info .purshcing-info-section3 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section3 End*/

/* total Start */
.Invoices1 .Invoice-details .purshcing-info .iteam-total {
    /* position: relative; */
    font-size: 15px;
    padding: 0px 4px;
    display: flex;
    text-align: center;
    justify-content: end;

}

.Invoices1 .Invoice-details .purshcing-info .iteam-total .total-Qty {
    margin-right: 46%;
}

/* CGST Start */
.Invoices1 .Invoice-details .purshcing-info .Sub-Gst {
    font-size: 12px;
    position: relative;
    height: 2.5vh;
}

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .CGST {
    position: absolute;
    right:22%;
}

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .CGST-price {
    position: absolute;
    right: 1%;

}

/* CGST End */
/* SGST Start */

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .SGST {
    position: absolute;
    right:22%;
}

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .SGST-price:nth-child() {
    position: absolute;
    right: 1%;
}

/* SGST End */
/* Discount start */
.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .Disc {
    position: absolute;
    right:22%;
}

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .Disc-price {
    position: absolute;
    right: 1%;

}

/* Discount End */
/* Payable Amount start */

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .Net-amount {
    position: absolute;
    right:22%;
}

.Invoices1 .Invoice-details .purshcing-info .Sub-Gst .Net-price {
    position: absolute;
    right: 1%;

}

/* Payable Amount End */
/* total End */
.Invoices1 .Invoice-details .Note {
    margin-bottom: 30px;
    font-size: 15px;
}

.Invoices1 .Invoice-details .signeture-mens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Invoices1 .Invoice-details .signeture-mens .Mens {
    visibility: hidden;
}

.Invoices1 .Invoice-details .signeture-mens .signature .sig-box {
    width: 100px;
    height: 50px;
    /* border: 1px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    margin-bottom: 10px;
}

.Invoices1 .Invoice-details .signeture-mens .signature p {
    font-size: 12px;
    line-height: 15px;

}

.Invoices1 .Invoice-details .signeture-mens .signature a {
    font-size: 14px;
}

.Invoices1 .Invoice-details .Tearms-section .Tearms ul li {
    /* margin-left: 3px; */
    font-size: 11px;
    color: rgb(126, 126, 126);
}

.Invoices1 .Invoice-details .Tearms-section .Reciver-sig .signature {
    margin-top: 20px;
    /* position: relative; */
    width: 100%;
}


.Invoices1 .Invoice-details .Tearms-section .Reciver-sig .signature .sig-box {
    position: absolute;
    width: 100px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    right: 5%;
    bottom: 3%;
    margin-bottom: 10px;
}

.Invoices1 .Invoice-details .Tearms-section .Reciver-sig .signature P {
    position: absolute;
    font-size: 13px;
    right: 5%;
    bottom: 1%;
}
/* customer-info end */

.Invoices1 .logo img {
    width: 200px;
}
#gap {
    text-align: center;
    display: none;
    width: 100%;
    visibility: hidden;
    height: 10vh;
}
/* Media Query */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .Invoices1 {
        padding: 5px;
    }
    .Invoices1 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 680px;

    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
    #Spcae{
        margin-top: 30%;
        /* visibility: hidden; */
        display: block;
    }
    .Invoices1 .Invoice-details {
        width: 550px;
        height: 750px;
    }
    #gap{
        display: block;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
    #Spcae{
        margin-top: 10%;
        visibility: hidden;
        display: block;
    }
    .back{
        left:5% !important;
    }
}

/* Media Query for Laptops and Desktops */
@media (max-width: 1280px) {
    .Invoice1{
        height: auto;

    }
    .Invoice1{
       overflow-y: hidden !important;

    }
}

.printme { display: none;}
@media print { 
    .no-printme  { display: none;}
    .printme  { display: block;}
}
@media (max-width: 480px) {
    .purshcing-info {
        overflow-x: auto; /* Sirf table ke andar scroll aayega, pura page nahi hilega */
    }

    .purshcing-info table {
        min-width: 100%; /* Table screen ke andar fit ho */
        word-wrap: break-word;
    }
}


@media print {
    .no-printme { display: none; }
    .printme { display: block; }

    #invoice {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        /* Ensure white background */
        /* page-break-before: always; */
      }
}
