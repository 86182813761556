.Invoice5 {
    height: auto;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* background-color: #edf0ff; */
    overflow-y: auto;
}

.Invoices5 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow-y: auto;
    margin: 0px auto;
    padding: 20px;

}

#Spcae {
    display: none;
}
.Invoices5 .Invoice-details {
    padding: 20px;
    width:615px !important;
    height:794px;
    /* overflow-y: auto; */
    border: 1.5px solid black;
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    position: relative;
    /* margin-left: 20%; */
    /* border: 2px solid black; */
}

.Invoices5 .Invoice-details h2 {
    text-align: center;
    color: #4c69ff;
}

.back {
    font-size: 30px;
    position: absolute;
    left: 14%;
    top: 3%;
    cursor: pointer;
}
.Invoices5 .Invoice-details .divider{
    margin:5px 0px;
}
.Invoices5 .Invoice-details .divider hr {
    background-color:grey;
    border: none;
    height: 0.5px;
}

/* bussiness-info start */
.Invoices5 .Invoice-details .bussiness-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 28px;
    /* background-color: #eef9fe; */
}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-details {
    width: 60%;
    text-align: right;
    margin-top: -30px;
    margin-right: -12px;
}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-details h3 {
    margin: 8px 0px;
    font-size: 18px;
}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-details p {
    color: rgb(126, 126, 126);
    margin: 10px 0px;
    font-size: 15px;
    width: 100%;

}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-logo {
    width: 30%;
    height: 10%;
    text-align: right;
   margin-bottom: 10px;
   position:relative;
    margin-left: -55px;
}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-logo h3 {
    margin: 10px;
    font-size: 15px;
}

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-logo img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.Invoices5 .logo {
    position: absolute;
    bottom: 0%;
    right: 0%;
    opacity: 1;
}



/* bussiness-info end */


/* Divider Start */

#last {
    height: 1px;
    border: none;
    background-color: rgb(17, 92, 242);
    margin: 0%;
}

/* Divider End */


/* customer-info start */
.Invoices5 .Invoice-details .customer-info {
    display: flex;
    align-items: start;
    justify-content: space-between;
    font-size: 12px;
    color: rgb(126, 126, 126);
    padding-bottom: 20px;
    margin-top: -32px;
    margin-left: -10px;
}

.Invoices5 .Invoice-details .customer-info P {
    font-size: 15px;
    /* font-weight: 600; */
    color: #4c69ff;
  margin-bottom: 0px !important;
}

.Invoices5 .Invoice-details .purshcing-info ol {
    list-style: none;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    /* list-style: decimal;  */

}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-heading {
    text-align: left;
    width: 100%;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    color: #4c69ff;
    border-top: 2px solid #4c69ff;
    padding-top: 10px;
    /* background-color: rgba(128, 128, 128, 0.516); */
    /* background-color: #5ac8fa; */
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-heading .no-item {
    width: 50%;
    display: flex;
    align-items: center;

}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .no {
    width: 30%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .item {
    width: 60%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-heading .price-amount {
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section1 Start*/
.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section1 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}

.Invoices5 .Invoice-details .purshcing-info .items p {
    font-size: 10px;
    /* margin-bottom:5px; */
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section1 ol {
    margin: 0px;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;

}

.Invoices5 .Invoice-details .purshcing-info .items {
    margin: 0;
    margin-left: 1%;
    font-size: 12px;
    color: gray;
}


.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .item {
    display: block;
    width: 70%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section1 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section2 Start*/
.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section2 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: white;

}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item {
    width: 40%;
    display: flex;
    align-items: center;

}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .no {
    width: 20%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .item {
    width: 70%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section2 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10% !important;
}

/*  purshcing-info-section2 End*/

/*  purshcing-info-section3 Start*/
.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section3 {
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item {
    width: 30%;
    display: flex;
    align-items: center;

}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .no {
    width: 20%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .item {
    width: 70%;
}

.Invoices5 .Invoice-details .purshcing-info .purshcing-info-section3 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section3 End*/

/* total Start */
.Invoices5 .Invoice-details .purshcing-info .iteam-total {
    font-size: 15px;
    padding: 0px 4px;
    display: flex;
    text-align: center;
    justify-content: end;

}

.Invoices5 .Invoice-details .purshcing-info .iteam-total .total-Qty {
    margin-right: 46%;
}

/* CGST Start */
.Invoices5 .Invoice-details .purshcing-info .Sub-Gst {
    font-size: 12px;
    position: relative;
    height: 3.5vh;
}

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .CGST {
    position: absolute;
    right: 22%;
}

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .CGST-price {
    position: absolute;
    right: 1%;

}

/* CGST End */
/* SGST Start */

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .SGST {

    position: absolute;
    right: 22%;
}

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .SGST-price {
    position: absolute;
    right: 1%;

}

/* SGST End */
/* Discount start */
.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .Disc {
    position: absolute;
    right: 22%;
}

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .Disc-price {
    position: absolute;
    right: 1%;

}

/* Discount End */
/* Payable Amount start */

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .Net-amount {
    right: 22%;
    position: absolute;
    padding-right: 5px;

}

.Invoices5 .Invoice-details .purshcing-info .Sub-Gst .Net-price {
    position: absolute;
    right: 1%;
    padding-right: 5px;

}

/* Payable Amount End */
/* total End */
.Invoices5 .Invoice-details .Note {
    margin-bottom: 10px;
    font-size: 13px;
    margin-left: -5px;
}

.Invoices5 .Invoice-details .signeture-mens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top:240px
}

.Invoices5 .Invoice-details .signeture-mens .Mens {
    visibility: hidden;
}

.Invoices5 .Invoice-details .signeture-mens .signature .sig-box {
    width: 100px;
    height: 50px;
    /* border: 1px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    margin-bottom: 10px;
}

.Invoices5 .Invoice-details .signeture-mens .signature p {
    font-size: 12px;
    line-height: 15px;

}

.Invoices5 .Invoice-details .signeture-mens .signature a {
    font-size: 14px;
}

.Invoices5 .Invoice-details .Tearms-section .Tearms ul li {
    margin-left: 5px;
    font-size: 11px;
    color: rgb(126, 126, 126);
}

.Invoices5 .Invoice-details .Tearms-section .Reciver-sig .signature {
    margin-top: 30px;
    /* position: relative; */
    width: 100%;
}

.Invoices5 .Invoice-details .Tearms-section .Reciver-sig .signature .sig-box {
    position: absolute;
    width: 100px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    right: 5%;
    bottom: 5%;
    margin-bottom: 10px;
}

.Invoices5 .Invoice-details .Tearms-section .Reciver-sig .signature P {
    position: absolute;
    font-size: 13px;
    right: 3%;
    bottom: 1%;
}

/* customer-info end */

.Invoices5 .logo img {
    width: 200px;
}

.Invoices5 .Tearms-section .Tearms {
    margin-top: 5px;
    margin-left: -6px;
}

.Invoices5 .Tearms-section .Tearms span {
    font-size: 13px;
}

.Invoices5 .Tearms-section .Tearms p {
    text-align: left;
    margin-top: 5px !important;
}
#gap {
    text-align: center;
    display: none;
    width: 100%;
    visibility: hidden;
    height: 10vh;
}


/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .Invoices5 {
        padding: 15px;
    }

.Invoices5 .Invoice-details .bussiness-info .bussiness-info-logo img {
    padding-left: 10%;
}

    .Invoices5 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 680px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
    .Invoices5 .Invoice-details {
        padding: 20px;
        width: 550px !important;
        height: 750px;
        /* margin-top: 20% !important; */
    }
    #gap{
        display: block;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
    #Spcae {
        margin-top: 10%;
        visibility: hidden;
        display: block;
    }
}

/* Media Query for Laptops and Desktops */
@media (max-width: 1280px) {
    .Invoice1 {
        height: auto;
    }
}
/* 
.div-height{
height: 300px !important;
} */

.Cus-name{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    /* color: lightgrey; */
}

.Cus-name h3{
    color: black;
}
.Cus-name span{

}
.Date span{
color: black;
font-size:14px
}
.Gst span{
color: black;
font-size: 14px;
}
.table_header{
height: 30px;
}