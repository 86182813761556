.Invoice6 {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #edf0ff; */
    overflow-y: auto;
}

.Invoices6 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    padding: 20px !important;
    font-family: 'Just Sans', sans-serif;
    overflow: hidden;
}

#Spcae {
    display: none;
}

.Invoices6 .Invoice-details {
    padding: 20px;
    width: 615px !important;
    min-height:850px;
    /* overflow-y: auto; */
    /* border: 1.5px solid black; */
    /* border-radius: 10px; */
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0cm 5px -1px, rgba(0, 0, 0, 0.3) 0px 0px 3px -1px;
    position: relative;
    /* margin-left: 20%; */

}

.back {
    font-size: 30px;
    position: absolute;
    left: 14%;
    top: 3%;
    cursor: pointer;
}

.Invoices6 .Invoice-details .divider {
    margin: 0;
}

.Invoices6 .Invoice-details .divider hr {
    background-color: grey;
    border: none;
    height: 0.5px;
    width: 143%;
    display: block;
    /* padding-left: 30%; */
}

/* bussiness-info start */
.Invoices6 .Invoice-details .bussiness-info {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding-bottom: 18px;
    font-size: 13px;
    color: gray;
}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info {
    display: flex;
    align-items: start;
    justify-content: flex-start;
}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info .billing_details {
    width: 24%;
}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info .billing_details h2 {
    color: #84939d;

}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info .billing_details p {
    color: #84939d;
    margin: 0px !important;
    font-size: 14px;
}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info .billing_details span {
    font-size: 14px;
    margin: 0px !important;

}

.Invoices6 .Invoice-details .billing_details_or_purshcing-info .purshcing-info {
    width: 70%;
}

.Invoices6 .Invoice-details .bussiness-info h3 {
    font-size: 25px;
    color: #71838e;
}

.Invoices6 .Invoice-details .bussiness-info img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.Invoices6 .Invoice-details .bussiness-info span {
    display: block;
    margin-bottom: 2px;
}

.Invoices6 .Invoice-details .bussiness-info p {
    font-size: 15px;
    color: #71838e;
    margin: 15px 0px 5px 0px;
    font-weight: bold;
}

/* bussiness-info end */


/* Divider Start */



/* Divider End */


/* customer-info start */
.Invoices6 .Invoice-details .customer-info {
    display: flex;
    align-items: start;
    justify-content: space-between;
    font-size: 12px;
    color: rgb(126, 126, 126);
    padding-bottom: 10px;
}

.Invoices6 .Invoice-details .customer-info P {
    font-size: 12px;
    font-weight: 600;
    color: #4ca1ff;
    margin-top: 5px;
}

.Invoices6 .Invoice-details .purshcing-info ol {
    list-style: none;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    /* list-style: decimal;  */

}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-heading {
    text-align: left;
    width: 100%;
    padding: 2px 4px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    font-size: 13px;
    background-color: #71838e;
    color: white;
    padding-top: 5px;
    /* background-color: rgba(128, 128, 128, 0.516); */
    /* background-color: #5ac8fa; */
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-heading .no-item {
    width: 45%;
    display: flex;
    align-items: center;

}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .no {
    width: 30%;
    margin-left: 20px;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .item {
    width: 60%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-heading .price-amount {
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section1 Start*/
.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: #ced3d6;
}

.Invoices6 .Invoice-details .purshcing-info .items p {
    font-size: 10px;
    /* margin-bottom:5px; */
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 ol {
    margin: 0px;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .no p {
    margin-left: 30px;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;

}

.Invoices6 .Invoice-details .purshcing-info .items {
    margin: 0;
    margin-left: 1%;
    font-size: 12px;
    color: gray;
}


.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .item {
    display: block;
    width: 70%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section1 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section2 Start*/
.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section2 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: white;

}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item {
    width: 40%;
    display: flex;
    align-items: center;

}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .no {
    width: 20%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .item {
    width: 70%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section2 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10% !important;
}

/*  purshcing-info-section2 End*/

/*  purshcing-info-section3 Start*/
.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section3 {
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item {
    width: 30%;
    display: flex;
    align-items: center;

}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .no {
    width: 20%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .item {
    width: 70%;
}

.Invoices6 .Invoice-details .purshcing-info .purshcing-info-section3 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section3 End*/

/* total Start */
.Invoices6 .Invoice-details .purshcing-info .iteam-total {
    font-size: 15px;
    padding: 8px 4px;
    display: flex;
    text-align: center;
    justify-content: end;
}

.Invoices6 .Invoice-details .purshcing-info .iteam-total .total-Qty {
    margin-right: 46%;
}

/* CGST Start */
.Invoices6 .Invoice-details .purshcing-info .Sub-Gst {
    font-size: 12px;
    position: relative;
    height: 2.5vh;
    margin-top: 5px;
}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst:first-child {
    margin-top: 10px;

}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .CGST {
    position: absolute;
    right: 22%;
}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .CGST-price {
    position: absolute;
    right: 1%;

}

/* CGST End */
/* SGST Start */

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .SGST {

    position: absolute;
    right: 22%;
}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .SGST-price {
    position: absolute;
    right: 1%;

}

/* SGST End */
/* Discount start */
.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .Disc {
    position: absolute;
    right: 22%;
}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .Disc-price {
    position: absolute;
    right: 1%;

}

/* Discount End */
/* Payable Amount start */

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .Net-amount {
    right: 22%;
    position: absolute;
    padding-right: 5px;

}

.Invoices6 .Invoice-details .purshcing-info .Sub-Gst .Net-price {
    position: absolute;
    right: 1%;
    padding-right: 5px;

}

/* Payable Amount End */
/* total End */
.Invoices6 .Invoice-details .Note {
    /* margin-bottom: 10px; */
    font-size: 13px;
}

.Invoices6 .Invoice-details .signeture-mens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Invoices6 .Invoice-details .signeture-mens .Mens {
    visibility: hidden;
}

.Invoices6 .Invoice-details .signeture-mens .signature .sig-box {
    width: 100px;
    height: 50px;
    /* border: 1px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    /* margin-bottom: 10px; */
}

.Invoices6 .Invoice-details .signeture-mens .signature p {
    font-size: 12px;
    line-height: 15px;

}

.Invoices6 .Invoice-details .signeture-mens .signature a {
    font-size: 14px;
}

.Invoices6 .Invoice-details .Tearms-section .Tearms ul li {
    /* margin-left: 15px; */
    font-size: 11px;
    color: rgb(126, 126, 126);
}

.Invoices6 .Invoice-details .Tearms-section .Reciver-sig .signature {
    margin-top: 20px;
    /* position: relative; */
    width: 100%;
}

.Invoices6 .Invoice-details .Tearms-section .Reciver-sig .signature .sig-box {
    position: absolute;
    width: 100px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    right: 5%;
    /* bottom: 3%; */
    /* margin-bottom: 10px; */
}

.Invoices6 .Invoice-details .Tearms-section .Reciver-sig .signature P {
    position: absolute;
    font-size: 13px;
    right: 5%;
    /* bottom: 1%; */
}

/* customer-info end */

.Invoices6 .logo img {
    width: 200px;
}

.Invoices6 .Tearms-section .Tearms {
    margin-top: 100px;
}

.Invoices6 .Tearms-section .Tearms span {
    font-size: 16px;
    color: #84939d;
}

.Invoices6 .Tearms-section .Tearms p {
    text-align: left;
    margin-top: 5px !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .Invoices6 {
        padding: 5px;
    }

    .Invoices6 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 680px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
    .Invoices6 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 750px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
    #Spcae {
        margin-top: 10%;
        visibility: hidden;
        display: block;
    }
}

/* Media Query for Laptops and Desktops */
@media (max-width: 1280px) {
    .Invoice1 {
        height: auto;
    }
}

.table {
    width: 100%;
}


@media print {
    .no-printme { display: none; }
    .printme { display: block; }

    #invoice {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
      }
}