.space {
    margin: 6% 0;
}
#money{
    margin-bottom: 10px;
    list-style: none;
}
#curancy_section_heading{
    width: 100%;
}
#curancy_section_heading p{
    width: 25%;
}
#curancy_section_heading p:first-child{
    text-align: left;
}
#curancy_section_heading p:last-child{
    text-align:right;
}
#curancy_iteams{
    width: 100%;
    position: relative;
}
#curancy_iteams .selectcurrancy{
    margin: 0;
  position: absolute;
  top: 50%;
  left: 1%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#curancy_iteams li div{
    width: 25%;
}
#curancy_iteams li div:nth-child(2){
    text-align: center;
}
#curancy_iteams li div:nth-child(2) div{
    width: 100%;
    text-align: center;
}
#curancy_iteams li div:nth-child(3){
    text-align: center;
}
#curancy_iteams li div:nth-child(3) {
    text-align: center;
}
#curancy_iteams li div:nth-child(3) div{
    width: 100%;
    text-align: center;
}
.CurrencyResponse button ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(92, 92, 92, 0.10);
    cursor: pointer;
    width: 100%;
    text-decoration: none;
}
.CurrencyResponse button ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(92, 92, 92, 0.10);
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    margin: 10px 0px;
    padding: 12px 0px;
    color: dimgray;
    font-size: 15px;
}
#serch_currency_lable{
    display: flex;
    align-items: center;
}
#serch_currency_lable b{
    margin-bottom: 10px;
    margin-left: 15px;
}
/* .CurrencyResponse button ul li:hover {
    color: green;
    font-size: 20px;
    font-weight: 700;
    transform: scaleX(1.01);
    transition: all 0.2s;
} */
.selectcurrancy{
    color: green;
    font-size: 20px;
    font-weight: 700;
}

.CurrencyResponse button ul li div {
    width: 20%;
}

.CurrencyResponse button ul li div:first-child {
    text-align: left;
    width: 26%;
}

.CurrencyResponse button ul li div:last-child {
    text-align: right;
}

.CurrencyResponse button {
    display: block;
    margin: 0 auto;
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
}

.CurrencyResponse button ul {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.CurrencyResponse button .code_symbol {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CurrencyResponse button .code_symbol .coad .symbol {
    margin-left: 10px;
}

/* .currency_sec{
    height: auto !important;
} */

.main_sections{
    background-color: rgb(255, 255, 255);
/* color: rgb(42, 53, 71); */
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 7px;
box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
width: 100%;
padding: 10px 15px;
margin-bottom: 82px !important;
height:auto !important;
overflow: auto;
}