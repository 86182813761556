.Invoice7 {
    height: auto;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.Invoices7 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    margin: 20px auto;
    padding: 20px !important;
    font-family: 'Just Sans', sans-serif;
    overflow: hidden;
}

#Spcae {
    display: none;
}

.Invoices7 .Invoice-details {
    padding: 20px;
    width: 615px !important;
    width: 615px !important;
    min-height: 800px;
    /* height: 794px; */
    /* height:794px; */
    /* overflow-y: auto; */
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    background-color: white;
    position: relative;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0cm 5px -1px, rgba(0, 0, 0, 0.3) 0px 0px 3px -1px;
    overflow: hidden;
    /* margin-left: 20%; */

    /* margin-bottom: 20px; */
    /* border: 2px solid black; */
}

.Invoices7 .Invoice-details .box_section {
    width: 100%;
    height: 30px;
    margin-top: -15PX;
}

.Invoices7 .Invoice-details .box_section .box {
    width: 30px;
    height: 10px;
    background-color: #0cb0c9;
    float: right;
    height: 15px;
}

.Invoices7 .Invoice-details .box_section .box:nth-child(4) {
    background-color: #ff3b30;
    height: 15px;
}

.Invoices7 .Invoice-details .box_section .box:nth-child(3) {
    background-color: #af52de;
    height: 15px;
}

.Invoices7 .Invoice-details .box_section .box:nth-child(2) {
    background-color: #ff9500;
    height: 15px;
}

.Invoices7 .Invoice-details .box_section .box:nth-child(1) {
    background-color: #5856d6;
    height: 15px;
}

.back {
    font-size: 30px;
    position: absolute;
    left: 17%;
    top: 3%;
    cursor: pointer;
}

.Invoices7 .Invoice-details .divider hr {
    background-color: #4ca1ff;
    border: none;
    height: 0.5px;
}

/* pdf download or print  start*/
#pdf {
    position: absolute;
    right: 3%;
    top: 3%;
    color: white;
    text-decoration: none;
    background-color: #0a3a8d;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
    font-weight: 700;
    z-index: 1111111;
    margin-right: 90px;
}

#pdf:hover::before {
    transform: scaleX(1);
}

#pdf .button-content {
    position: relative;
    z-index: 1;
}

#pdf::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(82.3deg,
            rgba(93, 230, 222, 0.30),
            rgba(93, 230, 222, 0.30));
    transition: all 0.475s;
}

#pdfprint {
    position: absolute;
    right: 3%;
    top: 3%;
    color: white;
    text-decoration: none;
    background-color: #0a3a8d;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
    font-weight: 700;
    z-index: 1111111;
    /* margin-right: 120px; */
}

#pdfprint:hover::before {
    transform: scaleX(1);
}

#pdfprint .button-content {
    position: relative;
    z-index: 1;
}

#pdfprint::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(82.3deg,
            rgba(93, 230, 222, 0.30),
            rgba(93, 230, 222, 0.30));
    transition: all 0.475s;
}


/* pdf download or print end */

.Invoices7 .Invoice-details .Heading h2 {
    text-align: center;
    font-weight: lighter;
}

/* bussiness-info start */
.Invoices7 .Invoice-details .bussiness-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    /* background-color: #eef9fe; */
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-details {
    /* width: 100%;
    margin-right: 175px; */
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-detailes {
    width: 100%;
    margin-right: 150px;
}

.adress {
    color: rgb(126, 126, 126);
    margin-top: 10px;
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-details h3 {
    /* margin: 8px 0px; */
    font-size: 18px;
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-details p {
    color: rgb(126, 126, 126);
    margin: 0px 0px;
    font-size: 12px;
    width: 100%;
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-logo {
    width: 30%;
    text-align: right;
}

.Invoices7 .Invoice-details .bussiness-info .bussiness-info-logo img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    /* margin-right: -30px; */
}

.Invoices7 .logo {
    position: absolute;
    bottom: 0%;
    right: 0%;
    opacity: 1;
}

.Invoices7 .Invoice-details .divider {
    margin: 0% 0%;
    margin-bottom: 2%;
}

/* bussiness-info end */

.Invoices7 .Invoice-details .Tearms-section {
    /* background-color:#eef9fe; */
}

/* Divider Start */

#last {
    height: 1px;
    border: none;
    background-color: rgb(17, 92, 242);
    margin: 0%;
}

/* Divider End */


/* customer-info start */
.Invoices7 .Invoice-details .customer-info {
    font-size: 12px;
    color: rgb(126, 126, 126);
    /* background-color: #eef9fe; */
}

.Invoices7 .Invoice-details .purshcing-info {
    /* background-color: #eef9fe; */
}

.Invoices7 .Invoice-details .customer-info .Cusname-invoice-number {
    /* color:#4ca1ff; */
    font-weight: 500;
    font-size: 15px;
}

.Invoices7 .Invoice-details .customer-info .Cusname-Invoices1-number {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

}

.Invoices7 .Invoice-details .Tearms .bussiness-info {
    background-color: transparent;
}

.Invoices7 .Invoice-details .customer-info .Cusname-Invoices1-number .Cus-name {
    font-size: 13px;
    width: 60%;
    /* color: rgb(17, 92, 242); */
    ;
}

.Invoices7 .Invoice-details .customer-info .Gst-date {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* font-size: 5px; */
}

.Invoices7 .Invoice-details .customer-info .Gst-date .Gst {
    width: 60%;
}

.Invoices7 .Invoice-details .customer-info .Adress {
    width: 50%;
    margin-bottom: 5px;
}

.Invoices7 .Invoice-details .customer-info .number {
    margin-bottom: 5px;
}

.Invoices7 .Invoice-details .customer-info .Email {
    margin-bottom: 10px;
}

.Invoices7 .Invoice-details .purshcing-info ol {
    list-style: none;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    /* margin-left: 5%; */
    background-color: #ced4d7;
    margin-bottom: 5px;
}

.Invoices7 .Invoice-details .purshcing-info ol li {
    border-bottom: 0.5px solid black;
}

.Invoices7 .Invoice-details .purshcing-info ol li:last-child {
    border-bottom: 2px solid black;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading {
    text-align: left;
    width: 100%;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    /* background-color: rgba(128, 128, 128, 0.516); */
    background-color: black;
    color: white;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading .no-item {
    width: 50%;
    display: flex;
    align-items: center;

}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .no {
    width: 30%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .item {
    width: 60%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading .price-amount {
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section1 Start*/
.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section1 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}

.Invoices7 .Invoice-details .purshcing-info .items p {
    font-size: 10px;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section1 ol {
    margin: 0px;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;

}

.Invoices7 .Invoice-details .purshcing-info .items {
    margin: 0;
    margin-left: 1%;
    font-size: 12px;
    color: gray;
}


.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .item {
    display: block;
    width: 70%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section1 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section2 Start*/
.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section2 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: white;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item {
    width: 40%;
    display: flex;
    align-items: center;

}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .no {
    width: 20%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .item {
    width: 70%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section2 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10% !important;
}

/*  purshcing-info-section2 End*/

/*  purshcing-info-section3 Start*/
.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section3 {
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item {
    width: 30%;
    display: flex;
    align-items: center;

}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .no {
    width: 20%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .item {
    width: 70%;
}

.Invoices7 .Invoice-details .purshcing-info .purshcing-info-section3 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section3 End*/

/* total Start */
.Invoices7 .Invoice-details .purshcing-info .iteam-total {
    font-size: 15px;
    padding: 0px 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Invoices7 .Invoice-details .purshcing-info .iteam-total .total-Amount_left p {
    font-size: 13px;
    font-weight: 500;
}

.Invoices7 .Invoice-details .purshcing-info .iteam-total .total-Amount_left span {
    font-size: 18px;
    font-weight: 700;
}

/* CGST Start */
.Invoices7 .Invoice-details .purshcing-info .Sub-Gst {
    font-size: 12px;
    position: relative;
    height: 2.5vh;
}

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .CGST {
    position: absolute;
    right: 22%;
}

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .CGST-price {
    position: absolute;
    right: 1%;
}

/* CGST End */
/* SGST Start */

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .SGST {

    position: absolute;
    right: 22%;
}

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .SGST-price {
    position: absolute;
    right: 1%;

}

/* SGST End */
/* Discount start */
.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .Disc {
    position: absolute;
    right: 22%;
}

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .Disc-price {
    position: absolute;
    right: 1%;
}

/* Discount End */
/* Payable Amount start */

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .Net-amount {
    right: 22%;
    position: absolute;
    /* background-color:#7cd2fa; */
    padding-right: 5px;

}

.Invoices7 .Invoice-details .purshcing-info .Sub-Gst .Net-price {
    position: absolute;
    right: 1%;
    /* background-color:#7cd2fa; */
    padding-right: 5px;

}

/* Payable Amount End */
/* total End */
.Invoices7 .Invoice-details .Note {
    margin-bottom: 10px;
    font-size: 13px;
}

.Invoices7 .Invoice-details .signeture-mens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Invoices7 .Invoice-details .signeture-mens .Mens {
    visibility: hidden;
}

.Invoices7 .Invoice-details .signeture-mens .signature .sig-box {
    width: 100px;
    height: 50px;
    /* border: 1px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    margin-bottom: 10px;
}

.Invoices7 .Invoice-details .signeture-mens .signature p {
    font-size: 12px;
    line-height: 15px;

}

.Invoices7 .Invoice-details .signeture-mens .signature a {
    font-size: 14px;
}

.Invoices7 .Invoice-details .Tearms-section .Tearms ul li {
    margin-left: 15px;
    font-size: 11px;
    color: rgb(126, 126, 126);
}

.Invoices7 .Invoice-details .Tearms-section .Reciver-sig .signature {
    margin-top: 20px;
    /* position: relative; */
    width: 100%;
}

.Invoices7 .Invoice-details .Tearms-section .Reciver-sig .signature .sig-box {
    position: absolute;
    width: 100px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    right: 5%;
    bottom: 3%;
    margin-bottom: 10px;
}

.Invoices7 .Invoice-details .Tearms-section .Reciver-sig .signature P {
    position: absolute;
    font-size: 13px;
    right: 5%;
    bottom: 1%;
}

/* customer-info end */

.Invoices7 .logo img {
    width: 200px;
}

/* Media Query */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .Invoices7 {
        padding: 5px;
    }

    .Invoices7 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 680px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
    .Invoices7 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 750px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
    #Spcae {
        margin-top: 10%;
        visibility: hidden;
        display: block;
    }

    .Invoices7 .Invoice-details .bussiness-info .bussiness-info-details {
        width: 228px
    }
}

/* Media Query for Laptops and Desktops */
@media (max-width: 1280px) {
    .Invoice1 {
        height: auto;
    }
}

.Dates {
    color: rgb(126, 126, 126) !important;
    font-size: 14px;
    margin-top: -9px;
}

@media (max-width: 768px) {
    .Invoices7 .Invoice-details {
        width: 95%;
        padding: 10px;
    }

    .Invoices7 .Invoice-details .Heading h2 {
        font-size: 18px;
    }

    .Invoices7 .Invoice-details .bussiness-info .bussiness-info-details h3 {
        font-size: 16px;
    }

    .Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading {
        font-size: 11px;
    }

    #pdf,
    #pdfprint {
        width: 100%;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .Invoices7 .Invoice-details {
        width: 100% !important;
        padding: 15px;
        overflow-x: hidden;
    }

    .Invoices7 .Invoice-details .Heading h2 {
        font-size: 16px;
    }

    .Invoices7 .Invoice-details .bussiness-info .bussiness-info-details h3 {
        font-size: 14px;
    }

    .Invoices7 .Invoice-details .purshcing-info .purshcing-info-heading {
        font-size: 10px;
        padding: 3px;
    }

    .Invoices7 .Invoice-details .iteam-total {
        flex-direction: column;
    }
}

@media print {
    .no-printme {
        display: none;
    }

    .printme {
        display: block;
    }

    #invoice {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        /* Ensure white background */
        /* page-break-before: always; */
    }
}