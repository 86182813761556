#preview_select{
    width: 15%;
    height: 100vh;
    position: fixed;
    left: 0%;
    top: 0%;
    overflow-y: auto ;
    background-image: url("../../public/assets/images/nft-gradient-bg-rotated.png");
    text-align: center;
    padding: 20px 0px;
}
@media (min-width: 375px) {
    #preview_select {
      inset-inline-start: 0 !important;
      width: 15.80rem !important;
    }
  }
  @media (max-width: 375px) {
    #preview_select {
      inset-inline-start: 0 !important;
      width: 15.80rem !important;
    }
  }
  
  @media (min-width: 1400px) {
    #preview_select {
      width: 17.1875rem !important;
    }
  }
  
  @media (min-width: 1650px) {
    #preview_select {
      width: 19.5rem !important;
    }
  }
  
#preview_select img{
    width: 70%;
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid black;
}
#preview_select img:hover{
   transition: all 0.5s;
   transform: scale(1.1);
}
#preview_selects{
    display: none;
}



.template-container {
    position: relative;
    display: inline-block; /* Ensures the container wraps the image properly */
}

.active-template {
    /* box-shadow: 0px 0px 20px skyblue; */
}

.checkmark-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    /* background-color: #4CAF50; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.checkmark-icon svg {
    width: 40px;
    height: 40px;
    fill: #4CAF50;
}

@media (max-width:1024px) {
    .hide_sidebar{
        display: none;
    }
    .display_sidebar{
        display:block;
    }
    .action_button{
        display: none !important;
    }
    .back_button{
        display: none;
    }
   
    .layer{
        width: 100% !important;
        padding: 0px;

    }

   
}

@media (min-width:1024px) {
    
    .display_button{
      display: none;  
    }
   
}
@media (min-width:1300px) and (max-width:1800px) {
    
  .back-button{
    margin-left:20% !important;  
    position: absolute;
  top: 0.625rem;
  /* inset-inline-end: 0.625rem; */
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid var(--input-form-light);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 107%;
  }
 
}
@media(min-width:1025) and(max-width:1400){
  #preview_select{
    display: none !important;
}
}