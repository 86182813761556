.Invoice4{
    height:auto;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}
.Invoices4 {
    width: 100%;
    height:100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow-y: auto;
    margin: 0px auto;
    padding: 20px;

}
#Spcae{
    display: none;
}
.Invoices4 .Invoice-details {
    padding: 20px;
    width:615px !important;
    height:794px;
    overflow-y: auto;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #eef9fe;
    position: relative;
}
.Invoices4 .Invoice-details h2{
    text-align: center;
    color: #4ca1ff;
}

.Invoices4 .Invoice-details .divider hr{
    background-color: #4ca1ff;
    border: none;
    height: 0.5px;
}


.Invoices4 .Invoice-details .Heading h2 {
    text-align: center;
    font-weight: lighter;
}

/* bussiness-info start */
.Invoices4 .Invoice-details .bussiness-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    background-color: #eef9fe;
}

.Invoices4 .Invoice-details .bussiness-info .bussiness-info-details {
    width: 60%;
}

.Invoices4 .Invoice-details .bussiness-info .bussiness-info-details h3 {
    margin: 8px 0px;
font-size:18px;
}

.Invoices4 .Invoice-details .bussiness-info .bussiness-info-details p {
    color: rgb(126, 126, 126);
   
    font-size: 12px;
    width: 100%;
}
.Invoices4 .Invoice-details .bussiness-info .bussiness-info-logo {
    width: 30%;
    text-align: right;
}

.Invoices4 .Invoice-details .bussiness-info .bussiness-info-logo img {
    width: 80px;
    height: 80px;
}

.Invoices4 .logo {
    position: absolute;
    bottom: 0%;
    right: 0%;
    opacity: 1;
}
.Invoices4 .Invoice-details .divider{
    margin:0% 0%;
    margin-bottom: 2%;
}

/* bussiness-info end */

.Invoices4 .Invoice-details .Tearms-section{
    background-color:#eef9fe;
}
/* Divider Start */

#last{
    height: 1px;
    border: none;
    background-color: rgb(17, 92, 242);
    margin: 0%;
}
/* Divider End */


/* customer-info start */
.Invoices4 .Invoice-details .customer-info {
    font-size: 12px;
    color: rgb(126, 126, 126);
    background-color: #eef9fe;
}
.Invoices4 .Invoice-details .purshcing-info {
    background-color: #eef9fe;
}
.Invoices4 .Invoice-details .customer-info .Cusname-invoice-number{
    color:#4ca1ff;
    font-weight:500;
    font-size: 15px;
}
.Invoices4 .Invoice-details .customer-info .Cusname-Invoices1-number {
    display: flex;
    align-items: center;
   

}
.Invoices4 .Invoice-details .Tearms .bussiness-info{
    background-color: transparent;
}
.Invoices4 .Invoice-details .customer-info .Cusname-Invoices1-number .Cus-name {
    font-size: 13px;
    width: 60%;
    color: rgb(17, 92, 242);
    ;
}

.Invoices4 .Invoice-details .customer-info .Gst-date {
    display: flex;
    align-items: center;
  
    /* font-size: 5px; */
}

.Invoices4 .Invoice-details .customer-info .Gst-date .Gst {
    width: 60%;
}

.Invoices4 .Invoice-details .customer-info .Adress {
    width: 50%;
 
}

.Invoices4 .Invoice-details .customer-info .number {
   
}

.Invoices4 .Invoice-details .customer-info .Email {
    
}

.Invoices4 .Invoice-details .purshcing-info ol {
    list-style: none;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    /* margin-left: 5%; */
}
.Invoices4 .Invoice-details .purshcing-info ol li:nth-child(odd){
    background-color:#9bddfb;
}
.Invoices4 .Invoice-details .purshcing-info ol li:nth-child(even){
    background-color:#d0eefc
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-heading {
    text-align: left;
    width: 100%;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    /* background-color: rgba(128, 128, 128, 0.516); */
    background-color: #5ac8fa;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-heading .no-item {
    width: 50%;
    display: flex;
    align-items: center;

}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .no {
    width: 30%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-heading .no-item .item {
    width: 60%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-heading .price-amount {
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section1 Start*/
.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section1 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}
.Invoices4 .Invoice-details .purshcing-info .items p{
    font-size:10px;
    /* margin-bottom:5px; */
}
.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section1 ol {
    margin: 0px;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;

}

.Invoices4 .Invoice-details .purshcing-info .items{
    margin: 0;
    margin-left:1%;
    font-size: 12px;
    color: gray;
} 


.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section1 .no-item .item {
   display: block;
    width: 70%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section1 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section2 Start*/
.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section2 {
    text-align: left;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    background-color: white;
    
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item {
    width: 40%;
    display: flex;
    align-items: center;

}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .no {
    width: 20%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section2 .no-item .item {
    width: 70%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section2 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10% !important;
}

/*  purshcing-info-section2 End*/

/*  purshcing-info-section3 Start*/
.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section3 {
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    /* background-color:#b3d6fe; */
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item {
    width: 30%;
    display: flex;
    align-items: center;

}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .no {
    width: 20%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section3 .no-item .item {
    width: 70%;
}

.Invoices4 .Invoice-details .purshcing-info .purshcing-info-section3 .price-amount {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*  purshcing-info-section3 End*/

/* total Start */
.Invoices4 .Invoice-details .purshcing-info .iteam-total {
    /* position: relative; */
    font-size: 15px;
    padding: 0px 4px;
    display: flex;
    text-align: center;
    justify-content: end;

}

.Invoices4 .Invoice-details .purshcing-info .iteam-total .total-Qty {
    margin-right: 46%;
}

/* CGST Start */
.Invoices4 .Invoice-details .purshcing-info .Sub-Gst {
    font-size: 12px;
    position: relative;
    height: 2.5vh;
}

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .CGST {
    position: absolute;
    right:22%;
}

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .CGST-price {
    position: absolute;
    right: 1%;

}

/* CGST End */
/* SGST Start */

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .SGST {

    position: absolute;
    right:22%;
}

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .SGST-price {
    position: absolute;
    right: 1%;

}

/* SGST End */
/* Discount start */
.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .Disc {
    position: absolute;
    right:22%;
}

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .Disc-price {
    position: absolute;
    right: 1%;

}

/* Discount End */
/* Payable Amount start */

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .Net-amount {
    right:22%;
    position: absolute;
    background-color:#7cd2fa;
    padding-right: 5px;

}

.Invoices4 .Invoice-details .purshcing-info .Sub-Gst .Net-price {
    position: absolute;
    right: 1%;
    background-color:#7cd2fa;
    padding-right: 5px;

}

/* Payable Amount End */
/* total End */
.Invoices4 .Invoice-details .Note {
    margin-bottom: 10px;
    font-size: 13px;
}

.Invoices4 .Invoice-details .signeture-mens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Invoices4 .Invoice-details .signeture-mens .Mens {
    visibility: hidden;
}

.Invoices4 .Invoice-details .signeture-mens .signature .sig-box {
    width: 100px;
    height: 50px;
    /* border: 1px solid black; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    margin-bottom: 10px;
}

.Invoices4 .Invoice-details .signeture-mens .signature p {
    font-size: 12px;
    line-height: 15px;

}

.Invoices4 .Invoice-details .signeture-mens .signature a {
    font-size: 14px;
}

.Invoices4 .Invoice-details .Tearms-section .Tearms ul li {
    margin-left: 15px;
    font-size: 11px;
    color: rgb(126, 126, 126);
}

.Invoices4 .Invoice-details .Tearms-section .Reciver-sig .signature {
    margin-top: 20px;
    /* position: relative; */
    width: 100%;
}

.Invoices4 .Invoice-details .Tearms-section .Reciver-sig .signature .sig-box {
    position: absolute;
    width: 100px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    right: 5%;
    bottom: 3%;
    margin-bottom: 10px;
}

.Invoices4 .Invoice-details .Tearms-section .Reciver-sig .signature P {
    position: absolute;
    font-size: 13px;
    right: 5%;
    bottom: 1%;
}

/* customer-info end */

.Invoices4 .logo img {
    width: 200px;
}
#gap {
    text-align: center;
    display: none;
    width: 100%;
    visibility: hidden;
    height: 10vh;
}

/* Media Query */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .Invoices4 {
        padding: 5px;
    }
    .Invoices4 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 680px;
        /* margin-top: 20% !important; */
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (max-width: 767px) {
    .Invoices4 .Invoice-details {
        padding: 20px;
        width: 550px;
        height: 750px;
    }
    #gap{
        display: block;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
    #Spcae{
        /* margin-top: 10%; */
        visibility: hidden;
        display: block;
    }
}

/* Media Query for Laptops and Desktops */
@media (max-width: 1280px) {
    .Invoice1{
        height: auto;
    }
}